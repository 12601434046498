<template>
  <div class="app-container">
    <div ref="contact" class="head-container">
      <el-select v-model="query.status" filterable clearable placeholder="审批状态" class="filter-item" style="width: 150px" @change="toQuery">
        <el-option v-for="(v,k) in statusLabel" :key="k" :label="v" :value="k" />
      </el-select>

      <quick-select url="api/brand" v-model="query.brandId" filterable placeholder="品牌" class="filter-item" @change="toQuery" clearable style="width: 200px;" />
      <quick-cascader class="filter-item" v-model="query.seriesId" url="api/series/tree" :params="{enabled: true}" root-key @change="toQuery" check-strictly filterable clearable placeholder="系列" expand-trigger="hover" style="width: 200px;" />
      <quick-cascader class="filter-item" v-model="query.categoryId" url="api/category/tree" @change="toQuery" filterable clearable placeholder="分类" expand-trigger="hover" style="width: 200px;" />
      <el-input v-model="query.name" clearable placeholder="输入商品名称搜索" prefix-icon="el-icon-search" style="width: 200px;" class="filter-item" @keypress.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <sku-selector class="filter-item" :multiple="false" button-text="申请调价" button-icon="el-icon-plus" @submit="add" />
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <el-table v-loading="loading" row-key="id" :data="data">
      <el-table-column prop="goodsType" label="商品类型" width="80" :formatter="v=>{return goodsType[v.goodsType]}" />
      <el-table-column prop="code" label="商品编码" min-width="130" />
      <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
      <el-table-column prop="name" label="商品名称" show-overflow-tooltip min-width="180" />
      <el-table-column prop="specs" label="规格" show-overflow-tooltip min-width="120" :formatter="$goodsSpecConvert" />
      <el-table-column label="调价类型" width="120">
        <template slot-scope="scope">
          <span>{{discountType[scope.row.priceType]}}</span>
        </template>
      </el-table-column>
      <el-table-column label="经销商/经销商类型" width="150">
        <template slot-scope="scope">
          <span>{{getDistributor(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="retailPrice" label="统一零售价" width="120" align="right" :formatter="$price" />
      <!-- <el-table-column prop="goodsPrice" label="调整前价格" width="120" align="right">
        <template slot-scope="scope" v-if="scope.row.oldPrice != null">
          <span>￥{{(scope.row.oldPrice / 100).toFixed(2)}}</span>
        </template>
      </el-table-column>-->
      <el-table-column prop="goodsPrice" label="申请调整价格" width="120" align="right" :formatter="$price" />
      <el-table-column label="起订量" width="60">
        <template slot-scope="scope">
          <span>{{(scope.row.modMin?scope.row.modMin:'')}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="beginTime" label="开始时间" width="160" :formatter="r => {return new Date(r.beginTime).format('yyyy/MM/dd hh:mm');}" />
      <el-table-column label="状态" width="60">
        <template slot-scope="scope">
          <span>{{statusLabel[scope.row.status]}}</span>
        </template>
      </el-table-column>
      <el-table-column width="160" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <!-- <el-button v-if="scope.row.status === 2" size="mini" type="text" @click="doDeal(scope.row.id)">即刻执行</el-button> -->
          <template v-if="scope.row.status === 0">
            <el-button size="mini" type="text" @click="doAudit(scope.row.id, true)">同意</el-button>
            <el-button class="danger" type="text" size="mini" @click="doAudit(scope.row.id, false)">拒绝</el-button>
          </template>

          <el-popover :ref="scope.row.id" placement="top" v-if="scope.row.status < 2">
            <p>确定要删除该调价申请吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="doDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
          </el-popover>
        </div>
      </el-table-column>
    </el-table>

    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <e-form ref="form" />
  </div>
</template>
<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { check, del, deal } from "@/api/goodsPriceApply";
import { get } from "@/api/dealerType";
import request from "@/utils/request";
import eForm from "./form";
import skuSelector from "@/views/assembly/goodsSelect";

export default {
  components: { eForm, skuSelector },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      goodsType: { spu: "SPU", sku: "SKU" },
      discountType: ["批发价", "经销商类型", "经销商"],
      statusLabel: ["待审批", "已拒绝", "已通过", "已完成"],
      dealerTypes: [],
      query: {
        status: null,
        brandId: null,
        seriesId: null,
        categoryId: null,
        name: null,
      },
    };
  },
  created() {
    get().then((res) => {
      this.dealerTypes = res;
      this.init();
    });
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/goodsPriceApply";
      let query = JSON.parse(JSON.stringify(this.query));
      this.params = Object.assign(
        { page: this.page, size: this.size, sort: "createAt,desc" },
        query
      );
      return true;
    },
    add(goods) {
      let g;
      if (goods.spu) {
        g = goods.spu;
        g.goodsType = "spu";
      } else {
        g = goods.sku;
        g.goodsType = "sku";
      }
      this.$refs.form && this.$refs.form.resetForm(g, true);
    },
    getDistributor(data) {
      let str = "";
      if (data.priceType == 2) {
        str = data.distributorName;
      } else if (data.priceType == 1) {
        if (this.dealerTypes && this.dealerTypes.length) {
          let dl = this.dealerTypes.find((o) => {
            return o.id === data.distributorLeve;
          });
          str = dl ? dl.name : "";
        }
      }
      return str;
    },
    doAudit(id, pass = false) {
      this.$confirm(
        `您确定要${pass ? "同意" : "拒绝"}该调价申请吗？`,
        "操作确认",
        {
          type: "warning",
        }
      ).then((_) => {
        check(id, pass).then((res) => {
          this.init();
          this.$notify({
            title: `${pass ? "同意" : "拒绝"}调价申请成功`,
            type: "success",
            duration: 2500,
          });
        });
      });
    },
    doDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除调价申请成功",
            type: "success",
            duration: 2500,
          });
        })
        .finally((_) => {
          this.delLoading = false;
        });
    },
    doDeal(id) {
      this.$confirm(`您确定要即刻执行该调价申请吗？`, "操作确认", {
        type: "warning",
      }).then((_) => {
        deal(id).then((res) => {
          this.$notify({
            title: "立即执行成功",
            type: "success",
            duration: 2500,
          });
          this.init();
        });
      });
    },
  },
};
</script>