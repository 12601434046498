import request from '@/utils/request'

export function del (id) {
  return request({
    url: "api/goodsPriceApply/" + id,
    method: "delete"
  });
}

export function check (id, pass) {
  return request({
    url: 'api/goodsPriceApply/check',
    method: 'post',
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    // data:JSON.stringify({applyId:id, pass:pass})
    data: `applyId=${id}&pass=${pass ? 1 : 0}`
  })
}

export function deal (id) {
  return request({
    url: "api/goodsPriceApply/deal/" + id,
    method: "post"
  });
}